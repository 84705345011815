import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import {
  Embellishment20,
  Embellishment21,
  Embellishment22,
} from "../components/Embellishments"

import "../css/contact-us.css"

function HeroSection({ title }) {
  return (
    <div className="section">
      <div className="padding---subpage-hero">
        <div className="subpage-hero-interior-padding background---black">
          <h1 className="h1 text---white">{title}</h1>
          <div className="notch---subpage-hero-1">
            <Embellishment20 />
          </div>
          <div className="notch---subpage-hero-2">
            <Embellishment21 />
          </div>
          <Embellishment22 />
        </div>
      </div>
    </div>
  )
}

function PartnerSection({ title, body }) {
  return (
    <div className="section">
      <div className="xl---padding-12---1-col">
        <h6 className="h6 label spacing---md partner-with-thesis">{title}</h6>
        <div className="w-form">
          <form
            id="email-form-2"
            name="email-form-2"
            data-name="Email Form 2"
            className="spacing---lg w-clearfix"
          >
            <div className="form-column-1">
              <label htmlFor="Thesis" className="field-label">
                This is a project for
              </label>
              <select
                id="field"
                name="field"
                className="form---dropdown w-select"
              >
                <option value="thesis">Thesis</option>
                <option value="fold">Fold</option>
                <option value="keep">Keep</option>
              </select>
            </div>
            <div className="form-column-1">
              <label htmlFor="First-Name" className="field-label">
                Name
              </label>
              <input
                type="text"
                className="form---field w-input"
                maxLength="256"
                name="First-Name"
                data-name="First Name"
                placeholder="First"
                id="First-Name"
              />
            </div>
            <div className="form-column-2">
              <label htmlFor="company" className="field-label">
                Company
              </label>
              <input
                type="text"
                className="form---field w-input"
                maxLength="256"
                name="company"
                data-name="company"
                placeholder="Company"
                id="company"
                required=""
              />
            </div>
            <div className="form-column-3">
              <label htmlFor="Title" className="field-label">
                Your Title
              </label>
              <input
                type="text"
                className="form---field w-input"
                maxLength="256"
                name="Title"
                data-name="Title"
                placeholder="Title"
                id="Title"
                required=""
              />
            </div>
            <div className="form-column-3">
              <label htmlFor="email-4" className="field-label">
                Email
              </label>
              <input
                type="email"
                className="form---field w-input"
                maxLength="256"
                name="email"
                data-name="email"
                placeholder="email@yourjob.com"
                id="email-4"
                required=""
              />
            </div>
            <div className="form-column-4">
              <label htmlFor="Message" className="field-label">
                Message
              </label>
              <textarea
                id="Message"
                name="Message"
                placeholder="Tell us about how you want to partner..."
                maxLength="5000"
                data-name="Message"
                className="form---text-area w-input"
              />
            </div>
            <input
              type="submit"
              value="submit"
              data-wait="Please wait..."
              className="button form w-button"
            />
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
        <div
          className="temp-email-copy"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </div>
  )
}

function ContactPage({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <HeroSection {...post.frontmatter.hero} />
      <PartnerSection {...post.frontmatter.partner} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ContactPage

export const query = graphql`
  query ContactUs($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          title
        }
        partner {
          title
          body
        }
      }
    }
  }
`
